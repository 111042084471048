/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900); }

.fa,
.fa-classic,
.fa-sharp,
.fas,
.fa-solid,
.far,
.fa-regular,
.fasr,
.fal,
.fa-light,
.fasl,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fass,
.fa-sharp-solid,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

.fas,
.fa-classic,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro'; }

.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands'; }

.fad,
.fa-classic.fa-duotone,
.fa-duotone {
  font-family: 'Font Awesome 6 Duotone'; }

.fass,
.fasr,
.fasl,
.fa-sharp {
  font-family: 'Font Awesome 6 Sharp'; }

.fass,
.fa-sharp {
  font-weight: 900; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em; }

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em; }

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em; }

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em); }

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em); }

.fa-beat {
  -webkit-animation-name: fa-beat;
          animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-bounce {
  -webkit-animation-name: fa-bounce;
          animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }

.fa-fade {
  -webkit-animation-name: fa-fade;
          animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-beat-fade {
  -webkit-animation-name: fa-beat-fade;
          animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flip {
  -webkit-animation-name: fa-flip;
          animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-shake {
  -webkit-animation-name: fa-shake;
          animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
          animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin-reverse {
  --fa-animation-direction: reverse; }

.fa-pulse,
.fa-spin-pulse {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
          animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    -webkit-animation-delay: -1ms;
            animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
            animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-duration: 0s;
            transition-duration: 0s; } }

@-webkit-keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25)); } }

@-webkit-keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0); } }

@-webkit-keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@-webkit-keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@-webkit-keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@-webkit-keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg); }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg); }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg); }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg); }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg); }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg); }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg); }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg); }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg); }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg); }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg); }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg); }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg); }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg); }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg); }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg); }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.fa-flip-vertical {
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
          transform: rotate(var(--fa-rotate-angle, none)); }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto); }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: var(--fa-inverse, #fff); }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

.fa-circle-chevron-right::before {
  content: "\f138"; }

.fa-chevron-circle-right::before {
  content: "\f138"; }

.fa-trash-can::before {
  content: "\f2ed"; }

.fa-trash-alt::before {
  content: "\f2ed"; }

.fa-text-height::before {
  content: "\f034"; }

.fa-calendar-days::before {
  content: "\f073"; }

.fa-calendar-alt::before {
  content: "\f073"; }

.fa-circle-minus::before {
  content: "\f056"; }

.fa-minus-circle::before {
  content: "\f056"; }

.fa-forward-step::before {
  content: "\f051"; }

.fa-step-forward::before {
  content: "\f051"; }

.fa-calendar-circle-user::before {
  content: "\e471"; }

.fa-arrow-up-long::before {
  content: "\f176"; }

.fa-long-arrow-up::before {
  content: "\f176"; }

.fa-strikethrough::before {
  content: "\f0cc"; }

.fa-pencil::before {
  content: "\f303"; }

.fa-pencil-alt::before {
  content: "\f303"; }

.fa-comments::before {
  content: "\f086"; }

.fa-note::before {
  content: "\e1ff"; }

.fa-box-archive::before {
  content: "\f187"; }

.fa-archive::before {
  content: "\f187"; }

.fa-reply::before {
  content: "\f3e5"; }

.fa-mail-reply::before {
  content: "\f3e5"; }

.fa-square-minus::before {
  content: "\f146"; }

.fa-minus-square::before {
  content: "\f146"; }

.fa-swatchbook::before {
  content: "\f5c3"; }

.fa-hourglass-end::before {
  content: "\f253"; }

.fa-hourglass-3::before {
  content: "\f253"; }

.fa-square-up-right::before {
  content: "\f360"; }

.fa-external-link-square-alt::before {
  content: "\f360"; }

.fa-circle-exclamation::before {
  content: "\f06a"; }

.fa-exclamation-circle::before {
  content: "\f06a"; }

.fa-flag::before {
  content: "\f024"; }

.fa-square-check::before {
  content: "\f14a"; }

.fa-check-square::before {
  content: "\f14a"; }

.fa-heading::before {
  content: "\f1dc"; }

.fa-header::before {
  content: "\f1dc"; }

.fa-up-to-line::before {
  content: "\f34d"; }

.fa-arrow-alt-to-top::before {
  content: "\f34d"; }

.fa-lock::before {
  content: "\f023"; }

.fa-users::before {
  content: "\f0c0"; }

.fa-eye-slash::before {
  content: "\f070"; }

.fa-chevron-up::before {
  content: "\f077"; }

.fa-angle-right::before {
  content: "\f105"; }

.fa-align-justify::before {
  content: "\f039"; }

.fa-gauge::before {
  content: "\f624"; }

.fa-dashboard::before {
  content: "\f624"; }

.fa-gauge-med::before {
  content: "\f624"; }

.fa-tachometer-alt-average::before {
  content: "\f624"; }

.fa-user::before {
  content: "\f007"; }

.fa-highlighter::before {
  content: "\f591"; }

.fa-bullhorn::before {
  content: "\f0a1"; }

.fa-globe::before {
  content: "\f0ac"; }

.fa-ban::before {
  content: "\f05e"; }

.fa-cancel::before {
  content: "\f05e"; }

.fa-star::before {
  content: "\f005"; }

.fa-maximize::before {
  content: "\f31e"; }

.fa-expand-arrows-alt::before {
  content: "\f31e"; }

.fa-heart-pulse::before {
  content: "\f21e"; }

.fa-heartbeat::before {
  content: "\f21e"; }

.fa-clipboard::before {
  content: "\f328"; }

.fa-underline::before {
  content: "\f0cd"; }

.fa-bold::before {
  content: "\f032"; }

.fa-gift::before {
  content: "\f06b"; }

.fa-chart-bar::before {
  content: "\f080"; }

.fa-bar-chart::before {
  content: "\f080"; }

.fa-square-plus::before {
  content: "\f0fe"; }

.fa-plus-square::before {
  content: "\f0fe"; }

.fa-image::before {
  content: "\f03e"; }

.fa-caret-up::before {
  content: "\f0d8"; }

.fa-rotate-left::before {
  content: "\f2ea"; }

.fa-rotate-back::before {
  content: "\f2ea"; }

.fa-rotate-backward::before {
  content: "\f2ea"; }

.fa-undo-alt::before {
  content: "\f2ea"; }

.fa-circle-play::before {
  content: "\f144"; }

.fa-play-circle::before {
  content: "\f144"; }

.fa-circle-check::before {
  content: "\f058"; }

.fa-check-circle::before {
  content: "\f058"; }

.fa-pause::before {
  content: "\f04c"; }

.fa-subscript::before {
  content: "\f12c"; }

.fa-cloud-arrow-up::before {
  content: "\f0ee"; }

.fa-cloud-upload::before {
  content: "\f0ee"; }

.fa-cloud-upload-alt::before {
  content: "\f0ee"; }

.fa-list-tree::before {
  content: "\e1d2"; }

.fa-list-dropdown::before {
  content: "\e1cf"; }

.fa-layer-group::before {
  content: "\f5fd"; }

.fa-italic::before {
  content: "\f033"; }

.fa-list-ol::before {
  content: "\f0cb"; }

.fa-list-1-2::before {
  content: "\f0cb"; }

.fa-list-numeric::before {
  content: "\f0cb"; }

.fa-up-down-left-right::before {
  content: "\f0b2"; }

.fa-arrows-alt::before {
  content: "\f0b2"; }

.fa-code::before {
  content: "\f121"; }

.fa-chart-pie::before {
  content: "\f200"; }

.fa-pie-chart::before {
  content: "\f200"; }

.fa-arrow-right::before {
  content: "\f061"; }

.fa-heart::before {
  content: "\f004"; }

.fa-cube::before {
  content: "\f1b2"; }

.fa-circle::before {
  content: "\f111"; }

.fa-clipboard-check::before {
  content: "\f46c"; }

.fa-wrench::before {
  content: "\f0ad"; }

.fa-eye::before {
  content: "\f06e"; }

.fa-pen::before {
  content: "\f304"; }

.fa-trash::before {
  content: "\f1f8"; }

.fa-quote-right::before {
  content: "\f10e"; }

.fa-quote-right-alt::before {
  content: "\f10e"; }

.fa-cubes::before {
  content: "\f1b3"; }

.fa-arrow-left::before {
  content: "\f060"; }

.fa-align-left::before {
  content: "\f036"; }

.fa-child::before {
  content: "\f1ae"; }

.fa-tag::before {
  content: "\f02b"; }

.fa-envelope::before {
  content: "\f0e0"; }

.fa-paperclip::before {
  content: "\f0c6"; }

.fa-indent::before {
  content: "\f03c"; }

.fa-circle-info::before {
  content: "\f05a"; }

.fa-info-circle::before {
  content: "\f05a"; }

.fa-camera::before {
  content: "\f030"; }

.fa-camera-alt::before {
  content: "\f030"; }

.fa-calendar-check::before {
  content: "\f274"; }

.fa-minus::before {
  content: "\f068"; }

.fa-subtract::before {
  content: "\f068"; }

.fa-arrow-left-long::before {
  content: "\f177"; }

.fa-long-arrow-left::before {
  content: "\f177"; }

.fa-clock::before {
  content: "\f017"; }

.fa-clock-four::before {
  content: "\f017"; }

.fa-backward-step::before {
  content: "\f048"; }

.fa-step-backward::before {
  content: "\f048"; }

.fa-caret-down::before {
  content: "\f0d7"; }

.fa-coins::before {
  content: "\f51e"; }

.fa-sliders::before {
  content: "\f1de"; }

.fa-sliders-h::before {
  content: "\f1de"; }

.fa-ellipsis-vertical::before {
  content: "\f142"; }

.fa-ellipsis-v::before {
  content: "\f142"; }

.fa-download::before {
  content: "\f019"; }

.fa-eye-dropper::before {
  content: "\f1fb"; }

.fa-eye-dropper-empty::before {
  content: "\f1fb"; }

.fa-eyedropper::before {
  content: "\f1fb"; }

.fa-align-center::before {
  content: "\f037"; }

.fa-outdent::before {
  content: "\f03b"; }

.fa-dedent::before {
  content: "\f03b"; }

.fa-house::before {
  content: "\f015"; }

.fa-home::before {
  content: "\f015"; }

.fa-home-alt::before {
  content: "\f015"; }

.fa-home-lg-alt::before {
  content: "\f015"; }

.fa-rotate-right::before {
  content: "\f2f9"; }

.fa-redo-alt::before {
  content: "\f2f9"; }

.fa-rotate-forward::before {
  content: "\f2f9"; }

.fa-stop::before {
  content: "\f04d"; }

.fa-upload::before {
  content: "\f093"; }

.fa-chart-mixed::before {
  content: "\f643"; }

.fa-analytics::before {
  content: "\f643"; }

.fa-angle-down::before {
  content: "\f107"; }

.fa-arrow-right-long::before {
  content: "\f178"; }

.fa-long-arrow-right::before {
  content: "\f178"; }

.fa-ellipsis::before {
  content: "\f141"; }

.fa-ellipsis-h::before {
  content: "\f141"; }

.fa-bell::before {
  content: "\f0f3"; }

.fa-superscript::before {
  content: "\f12b"; }

.fa-arrow-down::before {
  content: "\f063"; }

.fa-eraser::before {
  content: "\f12d"; }

.fa-earth-americas::before {
  content: "\f57d"; }

.fa-earth::before {
  content: "\f57d"; }

.fa-earth-america::before {
  content: "\f57d"; }

.fa-globe-americas::before {
  content: "\f57d"; }

.fa-poll-people::before {
  content: "\f759"; }

.fa-bars-staggered::before {
  content: "\f550"; }

.fa-reorder::before {
  content: "\f550"; }

.fa-stream::before {
  content: "\f550"; }

.fa-link::before {
  content: "\f0c1"; }

.fa-chain::before {
  content: "\f0c1"; }

.fa-play::before {
  content: "\f04b"; }

.fa-font::before {
  content: "\f031"; }

.fa-magnifying-glass::before {
  content: "\f002"; }

.fa-search::before {
  content: "\f002"; }

.fa-chevron-down::before {
  content: "\f078"; }

.fa-list-ul::before {
  content: "\f0ca"; }

.fa-list-dots::before {
  content: "\f0ca"; }

.fa-arrow-up::before {
  content: "\f062"; }

.fa-circle-user::before {
  content: "\f2bd"; }

.fa-user-circle::before {
  content: "\f2bd"; }

.fa-star-sharp::before {
  content: "\e28b"; }

.fa-copy::before {
  content: "\f0c5"; }

.fa-plus::before {
  content: "\2b"; }

.fa-add::before {
  content: "\2b"; }

.fa-xmark::before {
  content: "\f00d"; }

.fa-close::before {
  content: "\f00d"; }

.fa-multiply::before {
  content: "\f00d"; }

.fa-remove::before {
  content: "\f00d"; }

.fa-times::before {
  content: "\f00d"; }

.fa-angle-up::before {
  content: "\f106"; }

.fa-chevron-left::before {
  content: "\f053"; }

.fa-chevron-right::before {
  content: "\f054"; }

.fa-face-smile::before {
  content: "\f118"; }

.fa-smile::before {
  content: "\f118"; }

.fa-thumbtack::before {
  content: "\f08d"; }

.fa-thumb-tack::before {
  content: "\f08d"; }

.fa-rotate::before {
  content: "\f2f1"; }

.fa-sync-alt::before {
  content: "\f2f1"; }

.fa-gears::before {
  content: "\f085"; }

.fa-cogs::before {
  content: "\f085"; }

.fa-circle-chevron-left::before {
  content: "\f137"; }

.fa-chevron-circle-left::before {
  content: "\f137"; }

.fa-up-right-and-down-left-from-center::before {
  content: "\f424"; }

.fa-expand-alt::before {
  content: "\f424"; }

.fa-calendar::before {
  content: "\f133"; }

.fa-circle-plus::before {
  content: "\f055"; }

.fa-plus-circle::before {
  content: "\f055"; }

.fa-link-slash::before {
  content: "\f127"; }

.fa-chain-broken::before {
  content: "\f127"; }

.fa-chain-slash::before {
  content: "\f127"; }

.fa-unlink::before {
  content: "\f127"; }

.fa-check::before {
  content: "\f00c"; }

.fa-angle-left::before {
  content: "\f104"; }

.fa-triangle-exclamation::before {
  content: "\f071"; }

.fa-exclamation-triangle::before {
  content: "\f071"; }

.fa-warning::before {
  content: "\f071"; }

.fa-database::before {
  content: "\f1c0"; }

.fa-share::before {
  content: "\f064"; }

.fa-arrow-turn-right::before {
  content: "\f064"; }

.fa-mail-forward::before {
  content: "\f064"; }

.fa-right-left::before {
  content: "\f362"; }

.fa-exchange-alt::before {
  content: "\f362"; }

.fa-paper-plane::before {
  content: "\f1d8"; }

.fa-align-right::before {
  content: "\f038"; }

.fa-calendar-day::before {
  content: "\f783"; }

.fa-circle-xmark::before {
  content: "\f057"; }

.fa-times-circle::before {
  content: "\f057"; }

.fa-xmark-circle::before {
  content: "\f057"; }

.fa-note-sticky::before {
  content: "\f249"; }

.fa-sticky-note::before {
  content: "\f249"; }

.fa-exclamation::before {
  content: "\21"; }

.fa-print::before {
  content: "\f02f"; }

.fa-x::before {
  content: "\58"; }

.fa-video::before {
  content: "\f03d"; }

.fa-video-camera::before {
  content: "\f03d"; }

.fa-notdef::before {
  content: "\e1fe"; }

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }
:root, :host {
  --fa-style-family-duotone: 'Font Awesome 6 Duotone';
  --fa-font-duotone: normal 900 1em/1 'Font Awesome 6 Duotone'; }

@font-face {
  font-family: 'Font Awesome 6 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-duotone-900.woff2") format("woff2"), url("../webfonts/fa-duotone-900.ttf") format("truetype"); }

.fad,
.fa-duotone {
  position: relative;
  font-weight: 900;
  letter-spacing: normal; }

.fad::before,
.fa-duotone::before {
  position: absolute;
  color: var(--fa-primary-color, inherit);
  opacity: var(--fa-primary-opacity, 1); }

.fad::after,
.fa-duotone::after {
  color: var(--fa-secondary-color, inherit);
  opacity: var(--fa-secondary-opacity, 0.4); }

.fa-swap-opacity .fad::before,
.fa-swap-opacity .fa-duotone::before,
.fad.fa-swap-opacity::before,
.fa-duotone.fa-swap-opacity::before {
  opacity: var(--fa-secondary-opacity, 0.4); }

.fa-swap-opacity .fad::after,
.fa-swap-opacity .fa-duotone::after,
.fad.fa-swap-opacity::after,
.fa-duotone.fa-swap-opacity::after {
  opacity: var(--fa-primary-opacity, 1); }

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff); }

.fad.fa-stack-1x, .fad.fa-stack-2x,
.fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
  position: absolute; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen readers do not read off random characters that represent icons */
.fad.fa-circle-chevron-right::after, .fa-duotone.fa-circle-chevron-right::after {
  content: "\f138\f138"; }

.fad.fa-chevron-circle-right::after, .fa-duotone.fa-chevron-circle-right::after {
  content: "\f138\f138"; }

.fad.fa-trash-can::after, .fa-duotone.fa-trash-can::after {
  content: "\f2ed\f2ed"; }

.fad.fa-trash-alt::after, .fa-duotone.fa-trash-alt::after {
  content: "\f2ed\f2ed"; }

.fad.fa-text-height::after, .fa-duotone.fa-text-height::after {
  content: "\f034\f034"; }

.fad.fa-calendar-days::after, .fa-duotone.fa-calendar-days::after {
  content: "\f073\f073"; }

.fad.fa-calendar-alt::after, .fa-duotone.fa-calendar-alt::after {
  content: "\f073\f073"; }

.fad.fa-circle-minus::after, .fa-duotone.fa-circle-minus::after {
  content: "\f056\f056"; }

.fad.fa-minus-circle::after, .fa-duotone.fa-minus-circle::after {
  content: "\f056\f056"; }

.fad.fa-forward-step::after, .fa-duotone.fa-forward-step::after {
  content: "\f051\f051"; }

.fad.fa-step-forward::after, .fa-duotone.fa-step-forward::after {
  content: "\f051\f051"; }

.fad.fa-calendar-circle-user::after, .fa-duotone.fa-calendar-circle-user::after {
  content: "\e471\e471"; }

.fad.fa-arrow-up-long::after, .fa-duotone.fa-arrow-up-long::after {
  content: "\f176\f176"; }

.fad.fa-long-arrow-up::after, .fa-duotone.fa-long-arrow-up::after {
  content: "\f176\f176"; }

.fad.fa-strikethrough::after, .fa-duotone.fa-strikethrough::after {
  content: "\f0cc\f0cc"; }

.fad.fa-pencil::after, .fa-duotone.fa-pencil::after {
  content: "\f303\f303"; }

.fad.fa-pencil-alt::after, .fa-duotone.fa-pencil-alt::after {
  content: "\f303\f303"; }

.fad.fa-comments::after, .fa-duotone.fa-comments::after {
  content: "\f086\f086"; }

.fad.fa-note::after, .fa-duotone.fa-note::after {
  content: "\e1ff\e1ff"; }

.fad.fa-box-archive::after, .fa-duotone.fa-box-archive::after {
  content: "\f187\f187"; }

.fad.fa-archive::after, .fa-duotone.fa-archive::after {
  content: "\f187\f187"; }

.fad.fa-reply::after, .fa-duotone.fa-reply::after {
  content: "\f3e5\f3e5"; }

.fad.fa-mail-reply::after, .fa-duotone.fa-mail-reply::after {
  content: "\f3e5\f3e5"; }

.fad.fa-square-minus::after, .fa-duotone.fa-square-minus::after {
  content: "\f146\f146"; }

.fad.fa-minus-square::after, .fa-duotone.fa-minus-square::after {
  content: "\f146\f146"; }

.fad.fa-swatchbook::after, .fa-duotone.fa-swatchbook::after {
  content: "\f5c3\f5c3"; }

.fad.fa-hourglass-end::after, .fa-duotone.fa-hourglass-end::after {
  content: "\f253\f253"; }

.fad.fa-hourglass-3::after, .fa-duotone.fa-hourglass-3::after {
  content: "\f253\f253"; }

.fad.fa-square-up-right::after, .fa-duotone.fa-square-up-right::after {
  content: "\f360\f360"; }

.fad.fa-external-link-square-alt::after, .fa-duotone.fa-external-link-square-alt::after {
  content: "\f360\f360"; }

.fad.fa-circle-exclamation::after, .fa-duotone.fa-circle-exclamation::after {
  content: "\f06a\f06a"; }

.fad.fa-exclamation-circle::after, .fa-duotone.fa-exclamation-circle::after {
  content: "\f06a\f06a"; }

.fad.fa-flag::after, .fa-duotone.fa-flag::after {
  content: "\f024\f024"; }

.fad.fa-square-check::after, .fa-duotone.fa-square-check::after {
  content: "\f14a\f14a"; }

.fad.fa-check-square::after, .fa-duotone.fa-check-square::after {
  content: "\f14a\f14a"; }

.fad.fa-heading::after, .fa-duotone.fa-heading::after {
  content: "\f1dc\f1dc"; }

.fad.fa-header::after, .fa-duotone.fa-header::after {
  content: "\f1dc\f1dc"; }

.fad.fa-up-to-line::after, .fa-duotone.fa-up-to-line::after {
  content: "\f34d\f34d"; }

.fad.fa-arrow-alt-to-top::after, .fa-duotone.fa-arrow-alt-to-top::after {
  content: "\f34d\f34d"; }

.fad.fa-lock::after, .fa-duotone.fa-lock::after {
  content: "\f023\f023"; }

.fad.fa-users::after, .fa-duotone.fa-users::after {
  content: "\f0c0\f0c0"; }

.fad.fa-eye-slash::after, .fa-duotone.fa-eye-slash::after {
  content: "\f070\f070"; }

.fad.fa-chevron-up::after, .fa-duotone.fa-chevron-up::after {
  content: "\f077\f077"; }

.fad.fa-angle-right::after, .fa-duotone.fa-angle-right::after {
  content: "\f105\f105"; }

.fad.fa-align-justify::after, .fa-duotone.fa-align-justify::after {
  content: "\f039\f039"; }

.fad.fa-gauge::after, .fa-duotone.fa-gauge::after {
  content: "\f624\f624"; }

.fad.fa-dashboard::after, .fa-duotone.fa-dashboard::after {
  content: "\f624\f624"; }

.fad.fa-gauge-med::after, .fa-duotone.fa-gauge-med::after {
  content: "\f624\f624"; }

.fad.fa-tachometer-alt-average::after, .fa-duotone.fa-tachometer-alt-average::after {
  content: "\f624\f624"; }

.fad.fa-user::after, .fa-duotone.fa-user::after {
  content: "\f007\f007"; }

.fad.fa-highlighter::after, .fa-duotone.fa-highlighter::after {
  content: "\f591\f591"; }

.fad.fa-bullhorn::after, .fa-duotone.fa-bullhorn::after {
  content: "\f0a1\f0a1"; }

.fad.fa-globe::after, .fa-duotone.fa-globe::after {
  content: "\f0ac\f0ac"; }

.fad.fa-ban::after, .fa-duotone.fa-ban::after {
  content: "\f05e\f05e"; }

.fad.fa-cancel::after, .fa-duotone.fa-cancel::after {
  content: "\f05e\f05e"; }

.fad.fa-star::after, .fa-duotone.fa-star::after {
  content: "\f005\f005"; }

.fad.fa-maximize::after, .fa-duotone.fa-maximize::after {
  content: "\f31e\f31e"; }

.fad.fa-expand-arrows-alt::after, .fa-duotone.fa-expand-arrows-alt::after {
  content: "\f31e\f31e"; }

.fad.fa-heart-pulse::after, .fa-duotone.fa-heart-pulse::after {
  content: "\f21e\f21e"; }

.fad.fa-heartbeat::after, .fa-duotone.fa-heartbeat::after {
  content: "\f21e\f21e"; }

.fad.fa-clipboard::after, .fa-duotone.fa-clipboard::after {
  content: "\f328\f328"; }

.fad.fa-underline::after, .fa-duotone.fa-underline::after {
  content: "\f0cd\f0cd"; }

.fad.fa-bold::after, .fa-duotone.fa-bold::after {
  content: "\f032\f032"; }

.fad.fa-gift::after, .fa-duotone.fa-gift::after {
  content: "\f06b\f06b"; }

.fad.fa-chart-bar::after, .fa-duotone.fa-chart-bar::after {
  content: "\f080\f080"; }

.fad.fa-bar-chart::after, .fa-duotone.fa-bar-chart::after {
  content: "\f080\f080"; }

.fad.fa-square-plus::after, .fa-duotone.fa-square-plus::after {
  content: "\f0fe\f0fe"; }

.fad.fa-plus-square::after, .fa-duotone.fa-plus-square::after {
  content: "\f0fe\f0fe"; }

.fad.fa-image::after, .fa-duotone.fa-image::after {
  content: "\f03e\f03e"; }

.fad.fa-caret-up::after, .fa-duotone.fa-caret-up::after {
  content: "\f0d8\f0d8"; }

.fad.fa-rotate-left::after, .fa-duotone.fa-rotate-left::after {
  content: "\f2ea\f2ea"; }

.fad.fa-rotate-back::after, .fa-duotone.fa-rotate-back::after {
  content: "\f2ea\f2ea"; }

.fad.fa-rotate-backward::after, .fa-duotone.fa-rotate-backward::after {
  content: "\f2ea\f2ea"; }

.fad.fa-undo-alt::after, .fa-duotone.fa-undo-alt::after {
  content: "\f2ea\f2ea"; }

.fad.fa-circle-play::after, .fa-duotone.fa-circle-play::after {
  content: "\f144\f144"; }

.fad.fa-play-circle::after, .fa-duotone.fa-play-circle::after {
  content: "\f144\f144"; }

.fad.fa-circle-check::after, .fa-duotone.fa-circle-check::after {
  content: "\f058\f058"; }

.fad.fa-check-circle::after, .fa-duotone.fa-check-circle::after {
  content: "\f058\f058"; }

.fad.fa-pause::after, .fa-duotone.fa-pause::after {
  content: "\f04c\f04c"; }

.fad.fa-subscript::after, .fa-duotone.fa-subscript::after {
  content: "\f12c\f12c"; }

.fad.fa-cloud-arrow-up::after, .fa-duotone.fa-cloud-arrow-up::after {
  content: "\f0ee\f0ee"; }

.fad.fa-cloud-upload::after, .fa-duotone.fa-cloud-upload::after {
  content: "\f0ee\f0ee"; }

.fad.fa-cloud-upload-alt::after, .fa-duotone.fa-cloud-upload-alt::after {
  content: "\f0ee\f0ee"; }

.fad.fa-list-tree::after, .fa-duotone.fa-list-tree::after {
  content: "\e1d2\e1d2"; }

.fad.fa-list-dropdown::after, .fa-duotone.fa-list-dropdown::after {
  content: "\e1cf\e1cf"; }

.fad.fa-layer-group::after, .fa-duotone.fa-layer-group::after {
  content: "\f5fd\f5fd"; }

.fad.fa-italic::after, .fa-duotone.fa-italic::after {
  content: "\f033\f033"; }

.fad.fa-list-ol::after, .fa-duotone.fa-list-ol::after {
  content: "\f0cb\f0cb"; }

.fad.fa-list-1-2::after, .fa-duotone.fa-list-1-2::after {
  content: "\f0cb\f0cb"; }

.fad.fa-list-numeric::after, .fa-duotone.fa-list-numeric::after {
  content: "\f0cb\f0cb"; }

.fad.fa-up-down-left-right::after, .fa-duotone.fa-up-down-left-right::after {
  content: "\f0b2\f0b2"; }

.fad.fa-arrows-alt::after, .fa-duotone.fa-arrows-alt::after {
  content: "\f0b2\f0b2"; }

.fad.fa-code::after, .fa-duotone.fa-code::after {
  content: "\f121\f121"; }

.fad.fa-chart-pie::after, .fa-duotone.fa-chart-pie::after {
  content: "\f200\f200"; }

.fad.fa-pie-chart::after, .fa-duotone.fa-pie-chart::after {
  content: "\f200\f200"; }

.fad.fa-arrow-right::after, .fa-duotone.fa-arrow-right::after {
  content: "\f061\f061"; }

.fad.fa-heart::after, .fa-duotone.fa-heart::after {
  content: "\f004\f004"; }

.fad.fa-cube::after, .fa-duotone.fa-cube::after {
  content: "\f1b2\f1b2"; }

.fad.fa-circle::after, .fa-duotone.fa-circle::after {
  content: "\f111\f111"; }

.fad.fa-clipboard-check::after, .fa-duotone.fa-clipboard-check::after {
  content: "\f46c\f46c"; }

.fad.fa-wrench::after, .fa-duotone.fa-wrench::after {
  content: "\f0ad\f0ad"; }

.fad.fa-eye::after, .fa-duotone.fa-eye::after {
  content: "\f06e\f06e"; }

.fad.fa-pen::after, .fa-duotone.fa-pen::after {
  content: "\f304\f304"; }

.fad.fa-trash::after, .fa-duotone.fa-trash::after {
  content: "\f1f8\f1f8"; }

.fad.fa-quote-right::after, .fa-duotone.fa-quote-right::after {
  content: "\f10e\f10e"; }

.fad.fa-quote-right-alt::after, .fa-duotone.fa-quote-right-alt::after {
  content: "\f10e\f10e"; }

.fad.fa-cubes::after, .fa-duotone.fa-cubes::after {
  content: "\f1b3\f1b3"; }

.fad.fa-arrow-left::after, .fa-duotone.fa-arrow-left::after {
  content: "\f060\f060"; }

.fad.fa-align-left::after, .fa-duotone.fa-align-left::after {
  content: "\f036\f036"; }

.fad.fa-child::after, .fa-duotone.fa-child::after {
  content: "\f1ae\f1ae"; }

.fad.fa-tag::after, .fa-duotone.fa-tag::after {
  content: "\f02b\f02b"; }

.fad.fa-envelope::after, .fa-duotone.fa-envelope::after {
  content: "\f0e0\f0e0"; }

.fad.fa-paperclip::after, .fa-duotone.fa-paperclip::after {
  content: "\f0c6\f0c6"; }

.fad.fa-indent::after, .fa-duotone.fa-indent::after {
  content: "\f03c\f03c"; }

.fad.fa-circle-info::after, .fa-duotone.fa-circle-info::after {
  content: "\f05a\f05a"; }

.fad.fa-info-circle::after, .fa-duotone.fa-info-circle::after {
  content: "\f05a\f05a"; }

.fad.fa-camera::after, .fa-duotone.fa-camera::after {
  content: "\f030\f030"; }

.fad.fa-camera-alt::after, .fa-duotone.fa-camera-alt::after {
  content: "\f030\f030"; }

.fad.fa-calendar-check::after, .fa-duotone.fa-calendar-check::after {
  content: "\f274\f274"; }

.fad.fa-minus::after, .fa-duotone.fa-minus::after {
  content: "\f068\f068"; }

.fad.fa-subtract::after, .fa-duotone.fa-subtract::after {
  content: "\f068\f068"; }

.fad.fa-arrow-left-long::after, .fa-duotone.fa-arrow-left-long::after {
  content: "\f177\f177"; }

.fad.fa-long-arrow-left::after, .fa-duotone.fa-long-arrow-left::after {
  content: "\f177\f177"; }

.fad.fa-clock::after, .fa-duotone.fa-clock::after {
  content: "\f017\f017"; }

.fad.fa-clock-four::after, .fa-duotone.fa-clock-four::after {
  content: "\f017\f017"; }

.fad.fa-backward-step::after, .fa-duotone.fa-backward-step::after {
  content: "\f048\f048"; }

.fad.fa-step-backward::after, .fa-duotone.fa-step-backward::after {
  content: "\f048\f048"; }

.fad.fa-caret-down::after, .fa-duotone.fa-caret-down::after {
  content: "\f0d7\f0d7"; }

.fad.fa-coins::after, .fa-duotone.fa-coins::after {
  content: "\f51e\f51e"; }

.fad.fa-sliders::after, .fa-duotone.fa-sliders::after {
  content: "\f1de\f1de"; }

.fad.fa-sliders-h::after, .fa-duotone.fa-sliders-h::after {
  content: "\f1de\f1de"; }

.fad.fa-ellipsis-vertical::after, .fa-duotone.fa-ellipsis-vertical::after {
  content: "\f142\f142"; }

.fad.fa-ellipsis-v::after, .fa-duotone.fa-ellipsis-v::after {
  content: "\f142\f142"; }

.fad.fa-download::after, .fa-duotone.fa-download::after {
  content: "\f019\f019"; }

.fad.fa-eye-dropper::after, .fa-duotone.fa-eye-dropper::after {
  content: "\f1fb\f1fb"; }

.fad.fa-eye-dropper-empty::after, .fa-duotone.fa-eye-dropper-empty::after {
  content: "\f1fb\f1fb"; }

.fad.fa-eyedropper::after, .fa-duotone.fa-eyedropper::after {
  content: "\f1fb\f1fb"; }

.fad.fa-align-center::after, .fa-duotone.fa-align-center::after {
  content: "\f037\f037"; }

.fad.fa-outdent::after, .fa-duotone.fa-outdent::after {
  content: "\f03b\f03b"; }

.fad.fa-dedent::after, .fa-duotone.fa-dedent::after {
  content: "\f03b\f03b"; }

.fad.fa-house::after, .fa-duotone.fa-house::after {
  content: "\f015\f015"; }

.fad.fa-home::after, .fa-duotone.fa-home::after {
  content: "\f015\f015"; }

.fad.fa-home-alt::after, .fa-duotone.fa-home-alt::after {
  content: "\f015\f015"; }

.fad.fa-home-lg-alt::after, .fa-duotone.fa-home-lg-alt::after {
  content: "\f015\f015"; }

.fad.fa-rotate-right::after, .fa-duotone.fa-rotate-right::after {
  content: "\f2f9\f2f9"; }

.fad.fa-redo-alt::after, .fa-duotone.fa-redo-alt::after {
  content: "\f2f9\f2f9"; }

.fad.fa-rotate-forward::after, .fa-duotone.fa-rotate-forward::after {
  content: "\f2f9\f2f9"; }

.fad.fa-stop::after, .fa-duotone.fa-stop::after {
  content: "\f04d\f04d"; }

.fad.fa-upload::after, .fa-duotone.fa-upload::after {
  content: "\f093\f093"; }

.fad.fa-chart-mixed::after, .fa-duotone.fa-chart-mixed::after {
  content: "\f643\f643"; }

.fad.fa-analytics::after, .fa-duotone.fa-analytics::after {
  content: "\f643\f643"; }

.fad.fa-angle-down::after, .fa-duotone.fa-angle-down::after {
  content: "\f107\f107"; }

.fad.fa-arrow-right-long::after, .fa-duotone.fa-arrow-right-long::after {
  content: "\f178\f178"; }

.fad.fa-long-arrow-right::after, .fa-duotone.fa-long-arrow-right::after {
  content: "\f178\f178"; }

.fad.fa-ellipsis::after, .fa-duotone.fa-ellipsis::after {
  content: "\f141\f141"; }

.fad.fa-ellipsis-h::after, .fa-duotone.fa-ellipsis-h::after {
  content: "\f141\f141"; }

.fad.fa-bell::after, .fa-duotone.fa-bell::after {
  content: "\f0f3\f0f3"; }

.fad.fa-superscript::after, .fa-duotone.fa-superscript::after {
  content: "\f12b\f12b"; }

.fad.fa-arrow-down::after, .fa-duotone.fa-arrow-down::after {
  content: "\f063\f063"; }

.fad.fa-eraser::after, .fa-duotone.fa-eraser::after {
  content: "\f12d\f12d"; }

.fad.fa-earth-americas::after, .fa-duotone.fa-earth-americas::after {
  content: "\f57d\f57d"; }

.fad.fa-earth::after, .fa-duotone.fa-earth::after {
  content: "\f57d\f57d"; }

.fad.fa-earth-america::after, .fa-duotone.fa-earth-america::after {
  content: "\f57d\f57d"; }

.fad.fa-globe-americas::after, .fa-duotone.fa-globe-americas::after {
  content: "\f57d\f57d"; }

.fad.fa-poll-people::after, .fa-duotone.fa-poll-people::after {
  content: "\f759\f759"; }

.fad.fa-bars-staggered::after, .fa-duotone.fa-bars-staggered::after {
  content: "\f550\f550"; }

.fad.fa-reorder::after, .fa-duotone.fa-reorder::after {
  content: "\f550\f550"; }

.fad.fa-stream::after, .fa-duotone.fa-stream::after {
  content: "\f550\f550"; }

.fad.fa-link::after, .fa-duotone.fa-link::after {
  content: "\f0c1\f0c1"; }

.fad.fa-chain::after, .fa-duotone.fa-chain::after {
  content: "\f0c1\f0c1"; }

.fad.fa-play::after, .fa-duotone.fa-play::after {
  content: "\f04b\f04b"; }

.fad.fa-font::after, .fa-duotone.fa-font::after {
  content: "\f031\f031"; }

.fad.fa-magnifying-glass::after, .fa-duotone.fa-magnifying-glass::after {
  content: "\f002\f002"; }

.fad.fa-search::after, .fa-duotone.fa-search::after {
  content: "\f002\f002"; }

.fad.fa-chevron-down::after, .fa-duotone.fa-chevron-down::after {
  content: "\f078\f078"; }

.fad.fa-list-ul::after, .fa-duotone.fa-list-ul::after {
  content: "\f0ca\f0ca"; }

.fad.fa-list-dots::after, .fa-duotone.fa-list-dots::after {
  content: "\f0ca\f0ca"; }

.fad.fa-arrow-up::after, .fa-duotone.fa-arrow-up::after {
  content: "\f062\f062"; }

.fad.fa-circle-user::after, .fa-duotone.fa-circle-user::after {
  content: "\f2bd\f2bd"; }

.fad.fa-user-circle::after, .fa-duotone.fa-user-circle::after {
  content: "\f2bd\f2bd"; }

.fad.fa-star-sharp::after, .fa-duotone.fa-star-sharp::after {
  content: "\e28b\e28b"; }

.fad.fa-copy::after, .fa-duotone.fa-copy::after {
  content: "\f0c5\f0c5"; }

.fad.fa-plus::after, .fa-duotone.fa-plus::after {
  content: "\2b\2b"; }

.fad.fa-add::after, .fa-duotone.fa-add::after {
  content: "\2b\2b"; }

.fad.fa-xmark::after, .fa-duotone.fa-xmark::after {
  content: "\f00d\f00d"; }

.fad.fa-close::after, .fa-duotone.fa-close::after {
  content: "\f00d\f00d"; }

.fad.fa-multiply::after, .fa-duotone.fa-multiply::after {
  content: "\f00d\f00d"; }

.fad.fa-remove::after, .fa-duotone.fa-remove::after {
  content: "\f00d\f00d"; }

.fad.fa-times::after, .fa-duotone.fa-times::after {
  content: "\f00d\f00d"; }

.fad.fa-angle-up::after, .fa-duotone.fa-angle-up::after {
  content: "\f106\f106"; }

.fad.fa-chevron-left::after, .fa-duotone.fa-chevron-left::after {
  content: "\f053\f053"; }

.fad.fa-chevron-right::after, .fa-duotone.fa-chevron-right::after {
  content: "\f054\f054"; }

.fad.fa-face-smile::after, .fa-duotone.fa-face-smile::after {
  content: "\f118\f118"; }

.fad.fa-smile::after, .fa-duotone.fa-smile::after {
  content: "\f118\f118"; }

.fad.fa-thumbtack::after, .fa-duotone.fa-thumbtack::after {
  content: "\f08d\f08d"; }

.fad.fa-thumb-tack::after, .fa-duotone.fa-thumb-tack::after {
  content: "\f08d\f08d"; }

.fad.fa-rotate::after, .fa-duotone.fa-rotate::after {
  content: "\f2f1\f2f1"; }

.fad.fa-sync-alt::after, .fa-duotone.fa-sync-alt::after {
  content: "\f2f1\f2f1"; }

.fad.fa-gears::after, .fa-duotone.fa-gears::after {
  content: "\f085\f085"; }

.fad.fa-cogs::after, .fa-duotone.fa-cogs::after {
  content: "\f085\f085"; }

.fad.fa-circle-chevron-left::after, .fa-duotone.fa-circle-chevron-left::after {
  content: "\f137\f137"; }

.fad.fa-chevron-circle-left::after, .fa-duotone.fa-chevron-circle-left::after {
  content: "\f137\f137"; }

.fad.fa-up-right-and-down-left-from-center::after, .fa-duotone.fa-up-right-and-down-left-from-center::after {
  content: "\f424\f424"; }

.fad.fa-expand-alt::after, .fa-duotone.fa-expand-alt::after {
  content: "\f424\f424"; }

.fad.fa-calendar::after, .fa-duotone.fa-calendar::after {
  content: "\f133\f133"; }

.fad.fa-circle-plus::after, .fa-duotone.fa-circle-plus::after {
  content: "\f055\f055"; }

.fad.fa-plus-circle::after, .fa-duotone.fa-plus-circle::after {
  content: "\f055\f055"; }

.fad.fa-link-slash::after, .fa-duotone.fa-link-slash::after {
  content: "\f127\f127"; }

.fad.fa-chain-broken::after, .fa-duotone.fa-chain-broken::after {
  content: "\f127\f127"; }

.fad.fa-chain-slash::after, .fa-duotone.fa-chain-slash::after {
  content: "\f127\f127"; }

.fad.fa-unlink::after, .fa-duotone.fa-unlink::after {
  content: "\f127\f127"; }

.fad.fa-check::after, .fa-duotone.fa-check::after {
  content: "\f00c\f00c"; }

.fad.fa-angle-left::after, .fa-duotone.fa-angle-left::after {
  content: "\f104\f104"; }

.fad.fa-triangle-exclamation::after, .fa-duotone.fa-triangle-exclamation::after {
  content: "\f071\f071"; }

.fad.fa-exclamation-triangle::after, .fa-duotone.fa-exclamation-triangle::after {
  content: "\f071\f071"; }

.fad.fa-warning::after, .fa-duotone.fa-warning::after {
  content: "\f071\f071"; }

.fad.fa-database::after, .fa-duotone.fa-database::after {
  content: "\f1c0\f1c0"; }

.fad.fa-share::after, .fa-duotone.fa-share::after {
  content: "\f064\f064"; }

.fad.fa-arrow-turn-right::after, .fa-duotone.fa-arrow-turn-right::after {
  content: "\f064\f064"; }

.fad.fa-mail-forward::after, .fa-duotone.fa-mail-forward::after {
  content: "\f064\f064"; }

.fad.fa-right-left::after, .fa-duotone.fa-right-left::after {
  content: "\f362\f362"; }

.fad.fa-exchange-alt::after, .fa-duotone.fa-exchange-alt::after {
  content: "\f362\f362"; }

.fad.fa-paper-plane::after, .fa-duotone.fa-paper-plane::after {
  content: "\f1d8\f1d8"; }

.fad.fa-align-right::after, .fa-duotone.fa-align-right::after {
  content: "\f038\f038"; }

.fad.fa-calendar-day::after, .fa-duotone.fa-calendar-day::after {
  content: "\f783\f783"; }

.fad.fa-circle-xmark::after, .fa-duotone.fa-circle-xmark::after {
  content: "\f057\f057"; }

.fad.fa-times-circle::after, .fa-duotone.fa-times-circle::after {
  content: "\f057\f057"; }

.fad.fa-xmark-circle::after, .fa-duotone.fa-xmark-circle::after {
  content: "\f057\f057"; }

.fad.fa-note-sticky::after, .fa-duotone.fa-note-sticky::after {
  content: "\f249\f249"; }

.fad.fa-sticky-note::after, .fa-duotone.fa-sticky-note::after {
  content: "\f249\f249"; }

.fad.fa-exclamation::after, .fa-duotone.fa-exclamation::after {
  content: "\21\21"; }

.fad.fa-print::after, .fa-duotone.fa-print::after {
  content: "\f02f\f02f"; }

.fad.fa-x::after, .fa-duotone.fa-x::after {
  content: "\58\58"; }

.fad.fa-video::after, .fa-duotone.fa-video::after {
  content: "\f03d\f03d"; }

.fad.fa-video-camera::after, .fa-duotone.fa-video-camera::after {
  content: "\f03d\f03d"; }

.fad.fa-notdef::after, .fa-duotone.fa-notdef::after {
  content: "\e1fe\e1fe"; }
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-light: normal 300 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../webfonts/fa-light-300.woff2") format("woff2"), url("../webfonts/fa-light-300.ttf") format("truetype"); }

.fal,
.fa-light {
  font-weight: 300; }
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-regular: normal 400 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.ttf") format("truetype"); }

.far,
.fa-regular {
  font-weight: 400; }
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-solid: normal 900 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.ttf") format("truetype"); }

.fas,
.fa-solid {
  font-weight: 900; }
:root, :host {
  --fa-style-family-classic: 'Font Awesome 6 Pro';
  --fa-font-thin: normal 100 1em/1 'Font Awesome 6 Pro'; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../webfonts/fa-thin-100.woff2") format("woff2"), url("../webfonts/fa-thin-100.ttf") format("truetype"); }

.fat,
.fa-thin {
  font-weight: 100; }
